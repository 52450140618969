<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        Add New
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/packages')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-12 text-center">
        <img
          :src="body.image"
          v-if="id"
          class="profile-pic height-150 width-150 align-items-center"
          style="width: 100px;height: 100px;"
        />
        <!-- @error="$errorHandler" -->
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="name" class="form-label required">
            Name (EN)
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="body.name.en"
            :class="{ 'is-invalid': isInvalid && !body.name.en }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="name" class="form-label required">
            Name (DE)
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="body.name.de"
            :class="{ 'is-invalid': isInvalid && !body.name.de }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="Image" class="form-label required">
            Image
            <span>*</span>
          </label>

          <div class="align-items-center">
            <div class="profile-img-edit align-items-center">
              <div class="p-image">
                <div class="position-relative">
                  <i class="ri-pencil-line upload-button"></i>
                  <b-form-file
                    class="h-100 position-absolute"
                    accept="image/*"
                    @change="previewImage"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="slug" class="form-label required">
            Slug
            <span>*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="slug"
            v-model="body.slug"
            :class="{ 'is-invalid': isInvalid && !body.slug }"
            required
          />
        </div>
      </div>


      

      <div class="col-md-6">
        <div class="mb-3">
          <label for="inhomepage" class="form-label required">
           Körper(EN)
          </label>

          <Editor v-model="body.body.en" editorStyle="height: 320px" />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="inhomepage" class="form-label required">
           Körper(DE)
          </label>

          <Editor v-model="body.body.de" editorStyle="height: 320px" />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button
            label="Bearbeiten"
            icon="pi pi-pencil"
            class="ml-2 p-button-info"
            @click="update()"
            v-if="id"
            :disabled="disabledAdd"
          />
          <Button
            v-else
            label="Hinzufügen"
            icon="pi pi-plus"
            class="ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        name: { en: null, de: null },
        body: { en: null, de: null },
        slug: null,
        image: null,

      },
      id: null,


      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.image = image;
      });
    },
    save() {
      if (
        this.body.image &&
        this.body.slug &&
        this.body.name.en &&
        this.body.name.de
      ) {
        this.disabledAdd = true;

        this.$http.post(`packages`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'Done Successfully',
              detail: 'Added Successfully',
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push('/admin/packages');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail:  err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please add the required fields',
          life: 3000,
        });
      }
    },
    update() {
      if (
       this.body.image &&
        this.body.slug &&
        this.body.name.en &&
        this.body.name.de
      ) {
        this.disabledAdd = true;
        
        let data = { ...this.body };

        if (data.image.startsWith("http")) {
          delete data.image;
        }
        delete data.packagesPricesList

        this.$http.put(`packages/${this.id}`, data).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'Done Successfully',
              detail: 'Updated Successfully',
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push('/admin/packages');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail:  err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please add the required fields',
          life: 3000,
        });
      }
    },
  },
  created() {

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`packages/${this.id}`).then(
        (res) => {
          this.body = res.data.data;

          this.body.image = this.$baseUploadURL + this.body.image;
          this.body.name = JSON.parse(this.body.name);
          this.body.body = JSON.parse(this.body.body);


        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail:  err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>
